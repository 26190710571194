<template>
  <div class="flex items-center gap-2 mb-4 mt-4">
    <div class="p-2 bg-pink-100 dark:bg-pink-900 rounded">
      <SvgUse
        name="stock-line"
        path="/svgs/home.svg"
        class="w-6 h-6 text-pink-500 dark:text-white"
      />
    </div>
    <h3 class="text-xl font-bold">
      {{ LL.trendings() }}
    </h3>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-1 sm:mb-8">
    <HomeBlocksPostTrendingItem
      v-for="(p, index) in posts"
      :key="p.id"
      :index="index + 1"
      :title="p.title"
      :username="`${p.firstName} ${p.lastName}`"
      :slug="p.slug"
    />
  </div>
</template>

<script lang="ts" setup>
import type { TPostTrending } from "~/features/post";
import { typesafeI18n } from "~/locales/i18n-vue";

type Props = {
  posts: TPostTrending[];
};

withDefaults(defineProps<Props>(), { posts: () => [] });

const { LL } = typesafeI18n();
</script>
