<template>
  <LandingCard as="article">
    <div class="block sm:flex gap-3">
      <div class="flex items-center gap-4 sm:block">
        <UAvatar
          :src="post?.author?.avatar"
          :alt="fullName"
          size="md"
          :ui="{ size: { md: 'h-10 w-10 text-base' } }"
          loading="lazy"
        />
        <div>
          <div class="sm:hidden flex items-center gap-1">
            <UButton
              color="pink"
              variant="link"
              class="font-semibold"
              :to="`/users/${post.author.username}`"
              :padded="false"
              >{{ fullName }}</UButton
            >
            <p class="text-xs sm:text-xs text-gray-400 line-clamp-1">
              &#x2022; {{ fromNow(post.createdAt, locale) }}
            </p>
            <p class="text-xs sm:text-xs text-gray-400 line-clamp-1">
              &#x2022; {{ post.timeRead }} {{ LL.minutes_of_reading() }}
            </p>
          </div>
          <div class="flex items-center gap-1">
            <nuxt-link
              v-for="topic in take(post.topics, 3)"
              :key="topic.id"
              :to="`/posts/topics/${topic.slug}`"
              class="inline-flex sm:hidden in"
            >
              <div
                class="px-2 py-1.5 text-sm rounded font-medium text-gray-400 dark:text-gray-500 bg-gray-50 dark:bg-gray-950 hover:bg-gray-200 hover:dark:bg-gray-700 hover:text-gray-700"
              >
                {{ topic.title }}
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="grow space-y-1">
        <div class="hidden sm:flex sm:items-center sm:gap-1">
          <UButton
            color="pink"
            variant="link"
            class="font-semibold"
            :to="`/users/${post.author.username}`"
            :padded="false"
            >{{ fullName }}</UButton
          >
          <p class="text-xs sm:text-xs text-gray-400">
            &#x2022; {{ fromNow(post.createdAt, locale) }}
          </p>
          <p class="text-xs sm:text-xs text-gray-400">
            &#x2022; {{ post.timeRead }} {{ LL.minutes_of_reading() }}
          </p>
        </div>
        <div class="inline-flex items-center gap-2">
          <nuxt-link :to="`/posts/${post.slug}`" class="text-sm sm:text-lg font-bold">
            {{ truncate(post.title, 60) }}
          </nuxt-link>
          <nuxt-link
            v-for="topic in take(post.topics, 3)"
            :key="topic.id"
            :to="`/posts/topics/${topic.slug}`"
            class="hidden md:block"
          >
            <div
              class="px-2 py-1.5 text-sm rounded font-medium text-gray-400 dark:text-gray-500 bg-gray-50 dark:bg-gray-950 hover:bg-gray-200 hover:dark:bg-gray-700 hover:text-gray-700"
            >
              {{ topic.title }}
            </div>
          </nuxt-link>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex gap-4">
            <UButton
              icon="i-heroicons-eye"
              size="xs"
              color="white"
              variant="soft"
              :trailing="false"
              :padded="false"
              >{{ formatCompactNumber(post?.views) }}</UButton
            >
            <UButton
              icon="i-heroicons-bookmark"
              size="xs"
              color="white"
              variant="soft"
              :trailing="false"
              :padded="false"
              >{{ post?.bookmarks }}</UButton
            >
            <UButton
              icon="i-heroicons-chat-bubble-oval-left"
              size="xs"
              color="white"
              variant="soft"
              :trailing="false"
              :padded="false"
              >{{ post?.comments }}</UButton
            >
          </div>
          <UButton
            icon="i-heroicons-hand-raised"
            size="xs"
            color="white"
            variant="soft"
            :trailing="false"
            :padded="false"
            >{{ post?.claps }}</UButton
          >
        </div>
      </div>
    </div>
  </LandingCard>
</template>

<script setup lang="ts">
import type { TPost } from "~/features/post";
import { typesafeI18n } from "~/locales/i18n-vue";
import { take, truncate } from "@techmely/utils";
import { fromNow } from "~/utils/time";
import { formatCompactNumber } from "~/utils/number";

type Props = {
  post: TPost;
};

const props = defineProps<Props>();
const { LL, locale } = typesafeI18n();

const fullName = computed(
  () => `${props.post.author.firstName} ${props.post.author.lastName}`
);
</script>
