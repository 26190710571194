<template>
  <div>
    <div class="flex items-center gap-2 mb-8">
      <div class="p-2 bg-pink-100 dark:bg-pink-900 rounded flex">
        <UIcon
          name="i-heroicons-arrow-right-start-on-rectangle"
          class="h-6 w-6 text-pink-500 dark:text-white"
        />
      </div>
      <h3 class="text-xl font-bold">{{ LL.newest() }}</h3>
    </div>
    <div class="space-y-3 mb-6">
      <HomeBlocksPostLatestItem v-for="p in posts" :key="p.id" :post="p" />
    </div>
    <UButton
      to="/posts"
      no-prefetch
      class="text-pink-400 dark:text-white bg-pink-50 hover:bg-pink-400 hover:text-white dark:bg-pink-700 dark:hover:bg-pink-600"
    >
      {{ LL.view_more() }}
      <SvgUse name="cheveron-right-outline" class="text-inherit w-4 h-4" />
    </UButton>
  </div>
</template>

<script setup lang="ts">
import type { TPost } from "~/features/post";
import { typesafeI18n } from "~/locales/i18n-vue";

type Props = {
  posts: TPost[];
};

withDefaults(defineProps<Props>(), {
  posts: () => [],
});

const { LL } = typesafeI18n();
</script>
